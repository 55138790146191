module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Messi vs Ronaldo","short_name":"M vs R","start_url":"/","background_color":"#0B0E13","theme_color":"#0B0E13","display":"minimal-ui","icons":[{"src":"/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-80},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-158643934-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
