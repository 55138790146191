/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react";
import PageTransition from "./src/components/AppShell/PageTransition";
//import { globalHistory } from "@reach/router"

const transitionDelay = 0

export const wrapPageElement = ({ element, props }) => {
  return <PageTransition {...props}>{element}</PageTransition>;
};

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   if (location.action === 'PUSH') {
//     if(location.pathname.includes('/club-stats/')) {
//     } else {
//       window.setTimeout(() => window.scrollTo({top: 0, behavior: `smooth`}))
//     }
//     //console.log(globalHistory.location.pathname)
//   } else {
//     const savedPosition = getSavedScrollPosition(location)
//     window.setTimeout(
//       () => window.scrollTo(...(savedPosition || [0, 0])),
//       transitionDelay
//     )
//   }
//   return false
// }

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    if(location.pathname.includes('/club-stats/')) {
    } else {
      window.setTimeout(() => window.scrollTo({top:0,left:0,behavior:'instant'}), transitionDelay)
    }
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}

// Stops Gatsby prefetching the data for all links on the home page (aka the whole site)
// Was causing home page to download ~10mb of resources, causing bandwidth issues
export const disableCorePrefetching = () => true

// Show 'update app' message when data has been updated
// export const onServiceWorkerUpdateReady = () => {
//   const answer = window.confirm(
//     `This application has been updated. ` +
//       `Reload to display the latest stats?`
//   )
//   if (answer === true) {
//     window.location.reload()
//   }
// }

// Remove old SW from visitors' browsers
if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
  window.navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(r => r.unregister())
  })
}