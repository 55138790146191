import React from "react";
//import posed, { PoseGroup } from "react-pose";

//const timeout = 0;

class PageTransition extends React.PureComponent {
  render() {
    const { children, location } = this.props;

    // const RoutesContainer = posed.div({
    //   enter: {
    //     opacity: 1,
    //     duration: timeout,
    //     delay: timeout,
    //   },
    //   exit: {
    //     opacity: 0,
    //     duration: timeout,
    //   }
    // });

    return (
      <div>
        <div key={location.pathname}>{children}</div>
      </div>
    );
  }
}

export default PageTransition;


// import React from "react";
// import posed, { PoseGroup } from "react-pose";

// const timeout = 0;

// class PageTransition extends React.PureComponent {
//   render() {
//     const { children, location } = this.props;

//     const RoutesContainer = posed.div({
//       enter: {
//         opacity: 1,
//         duration: timeout,
//         delay: timeout,
//       },
//       exit: {
//         opacity: 0,
//         duration: timeout,
//       }
//     });

//     return (
//       <PoseGroup>
//         <RoutesContainer key={location.pathname}>{children}</RoutesContainer>
//       </PoseGroup>
//     );
//   }
// }

// export default PageTransition;